<template>
<div class="card card-shadow card-bars border-round-10">
  <div class="card-body">
    <div class="d-flex align-items-center">
      <div class="flex-grow-1 me-3">
        <div class="card-bars-count-num pe-2">{{ pantallas }}</div>
        <div class="content-estado card-bars-count-val">
          <p class="mb-0 me-2"> {{ opciones.title }}</p>
          <div v-if="opciones.title == 'Pantallas Online' ||  opciones.title == 'Pantallas Offline' ">
            <div :class="[opciones.estado === '0' ? 'dot-estado-online' : 'dot-estado-offline']"></div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center flex-shrink-0">
        <img 
          v-show="opciones.estado == '0'" 
          class="img-obj-60" 
          src="@/assets/img/tv/online_vermas.svg" 
        />
        <img 
          v-show="opciones.estado == '1' || opciones.estado == '2'|| opciones.estado == '3'" 
          class="img-obj-60" src="@/assets/img/tv/offline_vermas.svg" 
        />
        <button @click="openModal(opciones.estado)" class="p-0 btn btn-link btn-modal"> Ver más </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    pantallas: {
      type: String,
    },
    opciones: {
      type: Object
    }
  },
  data() {
    return {
      opciones_estado:{
        "0": "Pantallas Online",
        "1": "Pantallas Offline",
        "2": "Pantallas eliminadas",
        "3": "Canales eliminados",
      }
    }
  },
  methods: {
    openModal(id_) {
      this.$emit("openModal", id_);
    },
  },
};
</script>