<template>
  <section class="pantallas__modal mw-90 mw-90__1200">
    <div class="pantallas__top">
        <h2 class="pantallas__top-title"> 
          {{ estado == '2' ? 
              'Pantallas eliminadas' : 'Canales eliminados' }}
              ({{pagination.count}})
        </h2>
      <font-awesome-icon @click="closeModal" class="pantallas__close-icon" icon="times"></font-awesome-icon>
    </div>
    <div class="pantallas__table-container scroll-custom-550">
      <table class="pantallas__table">
        <thead class="pantallas__table-head">
          <tr class="pantallas__tr">
            <th class="pantallas__th-name">Nombre {{estado == '2' ? 'de la Pantalla' : 'del Canal' }} </th>
            <template v-if="estado == '2'">
              <th class="pantallas__th text-center">Ubicación de la pantalla</th>
            </template>
            <th class="pantallas__th text-center">Fecha de eliminación</th>
            <th class="pantallas__th text-center">Ususario</th>
          </tr>
        </thead>
        <tbody class="pantallas__table-body">
          <tr class="pantallas__tr" v-for="(el, i) in elementos" :key="i">
            <td class="pantallas__td">{{ el.nombre }}</td>
            <template v-if="estado == '2'">
              <td class="pantallas__th text-center">{{ el.ubicacion }}</td>
            </template>
            <td class="pantallas__td text-center">{{ el.fecha_eliminacion }}</td>
            <td class="pantallas__td text-center">
              <span
                    :content="el.rut_eli"
                    v-tippy="{
                      placement: 'top',
                      arrow: true,
                      trigger: 'mouseenter focus',
                    }"
                  >
                  {{ el.nombre_eli }}
                </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <c3-paginate 
      v-if="pagination.total_pages !== '0' && pagination.total_pages !== '1'"
      :pagination="pagination" 
      @pageChanged="onPageChange"
    />
  </section>

  
</template>

<script>
import moment from 'moment';

import C3Paginate from "@/components/C3Paginate.vue";

export default {
  components: {
    C3Paginate
  },
  props: {
    estado: {
      type: String,
    },
    elementos: {
      type: Array
    },
    pagination: {
      type: Object
    }
  },
  computed: {
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    onPageChange(e) {
      this.$emit("onPageChange", e);
    }
  },

  filters: {
    fechaModal(fecha) {
      if (!fecha) return '';
      return moment(fecha).format('DD/MM/YYYY hh:mm A')
    }
  }
};
</script>