<template>
  <section id="muro-digital">
    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          Crea, edita y administra tu Mural Digital estés donde estés.
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link
          :to="{ name: 'CanalCorporativo-add-canales' }"
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50"
        >
          <font-awesome-icon icon="plus" class="pe-2" />
          Crear canal
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h3 class="font-title-bold color-main">Total pantallas</h3>
            <PantallasPlan
              :pantallas="CantidadPantallasAdmin.pantallas_contrato"
            />
          </div>
          <div class="col-12 col-lg-6">
            <h3 class="font-title-bold color-main">Estado pantallas</h3>
            <div class="row" :key="rowCadsKey">
              <div class="col-12 col-xl-6 mb-3">
                <PantallasRegistroCard
                  :pantallas="CantidadPantallasAdmin.registradas"
                  :opciones="opciones_pantallas_registradas"
                />
              </div>
              <div class="col-12 col-xl-6 mb-3">
                <PantallasRegistroCard
                  :pantallas="CantidadPantallasAdmin.no_registradas"
                  :opciones="opciones_pantallas_no_registradas"
                />
              </div>
              <div class="col-12 col-xl-6 mb-3">
                <PantallasConexionCard
                  :pantallas="CantidadPantallasAdmin.online"
                  :opciones="opciones_pantallas_online"
                  @openModal="openModal('0')"
                />
              </div>
              <div class="col-12 col-xl-6 mb-3">
                <PantallasConexionCard
                  :pantallas="CantidadPantallasAdmin.offline"
                  :opciones="opciones_pantallas_offline"
                  @openModal="openModal('1')"
                />
              </div>
              <div class="col-12 col-xl-6 mb-3">
                <PantallasConexionCard
                  :pantallas="`${getNumLogsByType('pantalla')}`"
                  :opciones="opciones_pantallas_removed"
                  @openModal="openModal('2')"
                />
              </div>
              <div class="col-12 col-xl-6 mb-3">
                <PantallasConexionCard
                  :pantallas="`${getNumLogsByType('canal')}`"
                  :opciones="opciones_canales_removed"
                  @openModal="openModal('3')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tv-dash__modal" v-show="open_modal == true">
      <PantallasTableModal
        :pantallas="PantallasPaginadas"
        @closeModal="closeModal"
        :estado="estadoForModal"
      />
    </div>
    <div class="tv-dash__modal" v-show="open_log_modal == true">
      <DeletedTableModal
        :elementos="elementosPaginados"
        :pagination="pagination"
        @closeModal="closeModal"
        :estado="estadoForModal"
        @onPageChange="changePage"
      />
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import PantallasPlan from "./PantallasPlan.vue";
import PantallasRegistroCard from "./PantallasRegistroCard.vue";
import PantallasConexionCard from "./PantallasConexionCard.vue";
import PantallasTableModal from "./PantallasTableModal.vue";
import DeletedTableModal from "./DeletedTableModal.vue"; 
import moment from "moment";

export default {
  components: {
    PantallasPlan,
    PantallasRegistroCard,
    PantallasConexionCard,
    PantallasTableModal,
    DeletedTableModal,
  },
  data() {
    return {
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      rowCadsKey: 0,
      PantallasPaginadas: [],
      elementosPaginados: [],
      TotalPantallasOn: [],
      TotalPantallasOff: [],
      pagination: {
        actual_page: 0,
        count: "0",
        next_page: "0",
        previous_page: "0",
        total_pages: "0",
      },
      open_modal: false,
      open_log_modal: false,
      estadoForModal: "0",
      opciones_pantallas_registradas: {
        title: "Pantallas registradas",
        estado: "0",
      },
      opciones_pantallas_no_registradas: {
        title: "Pantallas sin registrar",
        estado: "1",
      },
      opciones_pantallas_online: {
        title: "Pantallas Online",
        estado: "0",
      },
      opciones_pantallas_offline: {
        title: "Pantallas Offline",
        estado: "1",
      },
      opciones_pantallas_removed: {
        title: "Pantallas eliminadas",
        estado: "2",
      },
      opciones_canales_removed: {
        title: "Canales eliminados",
        estado: "3",
      },
    };
  },
  computed: {
    ...mapGetters("canalCorporativoModule", ["getNumLogsByType"]),
    ...mapState("canalCorporativoModule", [
      "GetPantallaList",
      "CantidadPantallasAdmin",
      "logsIds",
      "logs",
    ]),
  },
  async mounted() {
    this.getCantidadPantallasAdmin();
    this.getTotalPantallasOnOff();
    Promise.all([
      this.getLogsIds({
        limite: 50,
        tipo: "pantalla",
      }),
      this.getLogsIds({
        limite: 50,
        tipo: "canal",
      }),
    ])
      .then(() => {
        Promise.all([
          this.getLogs({
            page: 1,
            tipo: "pantalla",
          }),
          this.getLogs({
            page: 1,
            tipo: "canal",
          }),
        ]);
      })
      .finally(() => {
        this.rowCadsKey++;
      });
  },
  methods: {
    ...mapActions("canalCorporativoModule", [
      "GetCantidadPantallasAction",
      "GetCantidadPantallasPaginadasAction",
      "GetCantidadPantallasOnOffAction",
      "getLogsIds",
      "getLogs",
    ]),

    async openModal(estado) {
      let ids_pantallas = [];
      this.estadoForModal = estado;
      if (estado == "0") {
        ids_pantallas = this.TotalPantallasOn.reduce(
          (prev, curr) => prev.concat(curr.ids),
          []
        );
      }
      if (estado == "1") {
        ids_pantallas = this.TotalPantallasOff.reduce(
          (prev, curr) => prev.concat(curr.ids),
          []
        );
      }
      if (estado == "0" || estado == "1") {
        await this.getPantallasPaginadas(ids_pantallas);
        this.open_modal = true;
        return;
      }
      if (estado == "2" || estado == "3") {
        await this.getElements(estado == "2" ? "pantalla" : "canal", 1);
      }
      this.open_log_modal = true;
    },

    async getElements(tipo, page) {
      await this.getLogs({ page, tipo });
      this.elementosPaginados = this.logs[tipo].map((e) => {
        const { eliminador } = e;
        return {
          fecha_eliminacion: e.fecha_eliminacion
            ? moment(e.fecha_eliminacion).format("DD/MM/YYYY hh:mm A")
            : "",

          id: tipo == "pantalla" ? e.id_pantalla : e.id_canal,
          nombre: tipo == "pantalla" ? e.nombre_pantalla : e.nombre_canal,
          nombre_eli: eliminador
            ? `${eliminador.primer_nombre} ${eliminador.apellido_paterno}`
            : "",
          rut_eli: eliminador
            ? `${eliminador.rut_usuario}-${eliminador.dv_usuario}`
            : "",
          ubicacion: tipo == "pantalla" ? e.ubicacion : '',
        };
      });
      this.pagination = {
        actual_page: page,
        count: `${this.getNumLogsByType(tipo)}`,
        total_pages: this.logsIds[tipo].length,
      };
    },

    changePage(page) {
      this.getElements(this.estadoForModal == "2" ? "pantalla" : "canal", page);
    },

    closeModal() {
      this.PantallasPaginadas = [];
      this.elementosPaginados = [];
      this.open_modal = false;
      this.open_log_modal = false;
    },

    async getCantidadPantallasAdmin() {
      const res = await this.GetCantidadPantallasAction();
      if (!res) {
        this.toastGenericError();
      }
    },

    async getPantallasPaginadas(ids_pantallas = [2, 3, 4, 5, 6]) {
      const payload = {
        idPantalla: ids_pantallas.map((i) => +i),
      };
      this.PantallasPaginadas = await this.GetCantidadPantallasPaginadasAction(
        payload
      );
      if (!this.PantallasPaginadas) {
        this.toastGenericError();
      }
    },

    async getTotalPantallasOnOff() {
      // TODO: combinar queries con apollo compose
      this.TotalPantallasOn = await this.GetCantidadPantallasOnOffAction({
        id_estado_pantalla: 1,
      });
      this.TotalPantallasOff = await this.GetCantidadPantallasOnOffAction({
        id_estado_pantalla: 3,
      });

      if (!this.TotalPantallasOn || !this.TotalPantallasOff) {
        this.toastGenericError();
      }
    },
  },
};
</script>
