<template>
  <div
    class="users-list__pagination"
    v-if="pagination.total_pages > 1"
  >
    <paginate
      v-model="pagination.actual_page"
      :page-count="+pagination.total_pages"
      :page-range="+page_range"
      :click-handler="clickHandler"
      :prev-text="`<div class='btn-prevnext'><i class='fas fa-chevron-left'></i></div>`"
      :next-text="`<div class='btn-prevnext'><i class='fas fa-chevron-right'></i></div>`"
      :container-class="'users-list__pagination-container'"
      :page-class="'pagination-item'"
    >
    </paginate>
  </div>
</template>

<script>
export default {
  props: {
    pagination: {
      type: Object,
      required: true
    },
    page_range: {
      type: Number,
      default: 3
    }
  },

  methods: {
    clickHandler(pageNum) {
      this.pagination.actual_page = pageNum;
      this.$emit("pageChanged", pageNum);
    }
  }

}
</script>